import React, { useState, useEffect } from 'react';
import { ClefSelector, PageContainer } from './styles'
import NoteComponent from '../../components/Note';
import NoteButtons from '../../components/NoteButton';

const initialTrebleNotes = ['A3', 'B3', 'C4', 'D4', 'E4', 'F4', 'G4', 'A4', 'B4', 'C5', 'D5', 'E5', 'F5', 'G5', 'A5', 'B5', 'C6'];
const initialBassNotes = ['C2', 'D2', 'E2', 'F2', 'G2', 'A2', 'B2', 'C3', 'D3', 'E3', 'F3', 'G3', 'A3', 'B3', 'C4', 'D4', 'E4'];

const NoteIdentificationPage: React.FC = () => {
	const [selectedClef, setSelectedClef] = useState('treble');
	const [feedback, setFeedback] = useState('');
	const [randomNote, setRandomNote] = useState('');

	useEffect(() => {
		generateRandomNote(selectedClef);
	}, [selectedClef]);

	const noteNames = ['C', 'D', 'E', 'F', 'G', 'A', 'B'];

	const [trebleNotes, setTrebleNotes] = useState([...initialTrebleNotes]);
	const [bassNotes, setBassNotes] = useState([...initialBassNotes]);

	const generateRandomNote = (cleff: String) => {
		let notesArray, setNotesArray, initialNotesArray;

		if (cleff === 'treble') {
			notesArray = trebleNotes;
			setNotesArray = setTrebleNotes;
			initialNotesArray = initialTrebleNotes;
		} else {
			notesArray = bassNotes;
			setNotesArray = setBassNotes;
			initialNotesArray = initialBassNotes;
		}
		console.log(notesArray)

		const randomIndex = Math.floor(Math.random() * notesArray.length);
		const selectedNote = notesArray[randomIndex];
		setRandomNote(selectedNote);

		// Remove the selected note from the array
		const updatedNotes = [...notesArray];
		updatedNotes.splice(randomIndex, 1);

		// If the array is empty, restore the initial state
		if (updatedNotes.length === 0) {
			setNotesArray([...initialNotesArray]);
		} else {
			setNotesArray(updatedNotes);
		}

	};

	const handleClefChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		setSelectedClef(event.target.value);
		generateRandomNote(selectedClef);
	};

	const handleNoteClick = (clickedNote: string) => {
		const isCorrect = checkAnswer(clickedNote);
		if (isCorrect) {
			setFeedback('');
			generateRandomNote(selectedClef);
		} else {
			setFeedback('Wrong button, try again!');
		}
	};

	const checkAnswer = (clickedNote: string) => {
		return clickedNote === randomNote[0];
	};

	return (
		<PageContainer>
			<h1>Music Theory Note Identification</h1>
			<ClefSelector value={selectedClef} onChange={handleClefChange}>
				<option value="treble">Treble Clef</option>
				<option value="bass">Bass Clef</option>
			</ClefSelector>
			<NoteComponent clef={selectedClef} note={randomNote} />
			<NoteButtons notes={noteNames} onNoteClick={handleNoteClick} />
			<div style={{ color: 'red' }}>{feedback}</div>
		</PageContainer>
	);
};

export default NoteIdentificationPage;
