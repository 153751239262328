import styled from 'styled-components';

export const ButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
`;

export const NoteButton = styled.button`
  font-size: 18px;
  margin: 10px 10px;
  padding: 10px 20px;
  cursor: pointer;
`;