import React from 'react';
import {ButtonContainer, NoteButton} from './styles'

interface NoteButtonsProps {
  notes: string[];
  onNoteClick: (note: string) => void;
}

const NoteButtons: React.FC<NoteButtonsProps> = ({ notes, onNoteClick }) => {
  return (
    <ButtonContainer>
      {notes.map((note) => (
        <NoteButton key={note} onClick={() => onNoteClick(note)} value={note} >
          {note}
        </NoteButton>
      ))}
    </ButtonContainer>
  );
};

export default NoteButtons;

