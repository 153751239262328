// NoteComponent.tsx
import React from 'react';
import { Staff, ClefSymbol, LinesContainer, Line, NoteSymbol, SupportLines } from "./styles"

interface NoteComponentProps {
	clef: string;
	note: string;
}

const NoteComponent: React.FC<NoteComponentProps> = ({ clef, note }) => {
	const notePositions: Record<string, Record<string, number>> = {
		"treble": {
			"A3": -17,
			"B3": -11,
			"C4": -5,
			"D4": 3,
			"E4": 9,
			"F4": 15,
			"G4": 21,
			"A4": 27,
			"B4": 33,
			"C5": 39,
			"D5": 45,
			"E5": 51,
			"F5": 57,
			"G5": 63,
			"A5": 69,
			"B5": 75,
			"C6": 81,
		},
		"bass": {
			"C2": -17,
			"D2": -11,
			"E2": -5,
			"F2": 3,
			"G2": 9,
			"A2": 15,
			"B2": 21,
			"C3": 27,
			"D3": 33,
			"E3": 39,
			"F3": 45,
			"G3": 51,
			"A3": 57,
			"B3": 63,
			"C4": 69,
			"D4": 75,
			"E4": 81,
		}
	};

	const position = notePositions[clef][note];

	return (
		<Staff>
			<ClefSymbol>{clef === 'treble' ? '𝄞' : '𝄢'}</ClefSymbol>
			<LinesContainer>
				{Array.from({ length: 5 }, (_, index) => (
					<Line key={`line-${index}`} />
				))}
				<NoteSymbol position={position} className={note}>
					<SupportLines className={note + '-' + clef} />
				</NoteSymbol>
			</LinesContainer>
		</Staff>
	);
};

export default NoteComponent;
