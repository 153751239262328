import styled from 'styled-components';

export const Staff = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const ClefSymbol = styled.div`
  font-size: 80px;
  margin-right: 10px;
  z-index: 1; 
`;


export const LinesContainer = styled.div`
  position: relative;
`;

export const Line = styled.div`
  width: 200px;
  height: 2px;
  background-color: black;
  margin-top: 10px;
  z-index: -1;
`;

export const NoteSymbol = styled.div<{ position: number }>`
  bottom: ${({ position }) => position}px;
  z-index: 2; 

  position: relative;
  display: inline-block;
  width: 18px;
  height: 6px;
  border-radius: 50%;
  border: 1px solid black;
  border-top-width: 4px;
  border-bottom-width: 4px;
  transform: rotatez(-15deg);

  .A3-treble, .C2-bass {
	  background: linear-gradient(0, black 0, black 2px, transparent 2px, transparent 12px, black 12px, black 14px, transparent 14px, transparent 24px);
	  position: relative;
	  left: -11px;
	  bottom: 10px;
	  width: 40px;
	  height: 14px;
	  transform: rotatez(15deg);
  }

  .B3-treble, .D2-bass {
	  background: linear-gradient(180deg, black 0, black 2px, transparent 2px, transparent 12px);
	  position: relative;
	  left: -11px;
	  bottom: 4px;
	  width: 40px;
	  height: 12px;
	  transform: rotatez(15deg);
  }

  .C4-treble, .E2-bass {
	  background: linear-gradient(180deg, black 0, black 2px, transparent 2px, transparent 12px);
	  position: relative;
	  left: -11px;
	  bottom: -2px;
	  width: 40px;
	  height: 12px;
	  transform: rotatez(15deg);
  }

  .A5-treble, .C4-bass {
	  background: linear-gradient(180deg, black 0, black 2px, transparent 2px, transparent 12px);
	  position: relative;
	  left: -11px;
	  bottom: -2px;
	  width: 40px;
	  height: 12px;
	  transform: rotatez(15deg);
  }

  .B5-treble, .D4-bass {
	  background: linear-gradient(180deg, black 0, black 2px, transparent 2px, transparent 12px);
	  position: relative;
	  left: -11px;
	  bottom: -8px;
	  width: 40px;
	  height: 12px;
	  transform: rotatez(15deg);
  }

  .C6-treble, .E4-bass {
	  background: linear-gradient(180deg, black 0, black 2px, transparent 2px, transparent 12px, black 12px, black 14px, transparent 14px, transparent 24px);
	  position: relative;
	  left: -11px;
	  bottom: -2px;
	  width: 40px;
	  height: 14px;
	  transform: rotatez(15deg);
  }
`;

export const SupportLines = styled.div``;

